.tooltip-content {
  border-radius: 4px;
  padding: 10px 15px;
  font-size: 14px;
  background-color: #000;
  color: #fff;
  animation-duration: 0.3s;
  animation-timing-function: cubic-bezier(0.16, 1, 0.3, 1);
  will-change: transform, opacity;
  z-index: 9000;
}

.tooltip-content[data-side="top"] {
  animation-name: slideDownAndFade;
}

.tooltip-content[data-side="bottom"] {
  animation-name: slideUpAndFade;
}

.tooltip-content[data-side="left"] {
  animation-name: slideRightAndFade;
}

.tooltip-content[data-side="right"] {
  animation-name: slideLeftAndFade;
}

.tooltip-arrow {
  fill: #000;
}

@keyframes slideUpAndFade {
  from {
    opacity: 0;
    transform: translateY(10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes slideDownAndFade {
  from {
    opacity: 0;
    transform: translateY(-10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes slideRightAndFade {
  from {
    opacity: 0;
    transform: translateX(-10px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes slideLeftAndFade {
  from {
    opacity: 0;
    transform: translateX(10px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}
